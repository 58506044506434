import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Pane, majorScale } from 'evergreen-ui';
import { gsap } from 'gsap';

import './App.css';
import CanvasComponent from './canvasComponent';
import Info from './assets/images/infoButton.svg';
import * as Tone from 'tone';
import Countdown from './countDown'; 
import audio from './assets/sounds/audio.wav'
import blue from './assets/sounds/blue.wav'
import green from './assets/sounds/green.wav'
import red from './assets/sounds/red.wav'
import yellow from './assets/sounds/yellow.wav'
import pink from './assets/sounds/pink.wav'
import violet from './assets/sounds/violet.wav'
import cyan from './assets/sounds/cyan.wav'
import YoVoiceIs from './assets/sounds/Your-voice-is.wav'
import {Howl} from 'howler';

let sound;

const sounds = {
  RED: red,
  YELLOW: yellow,
  GREEN: green,
  BLUE: blue,
  CYAN: cyan,
  VIOLET: violet,
  PINK: pink,
  'yo-voice-is': YoVoiceIs
}

const fixAudioContext = () => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  if (audioContext.state !== 'running') {
    return audioContext.resume();
  }
}

const DialogOverlay = ({ onClick }) => (
  <Pane className="dialog-overlay" onClick={onClick}></Pane>
);
let clck = true;

const preLoadSounds = () => {
  Object.keys(sounds).forEach((key) => {
    sound = new Howl({
      src: [sounds[key]],
      preload: true,
      volume: 0.6
    });
  });
}

const App = (e) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(false);
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const [gyroscopeData, setGyroscopeData] = useState({ gamma: 0, alpha: 0, beta: 0 });
  const [clicked, setClicked] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [count, setCount] = useState(0);
  const textRef = useRef(null)
  const [isTextReady, setIsTextReady] = useState(false);
  const [color, setColor] = useState(false);
  const [permission, setPermission] = useState(false);
  const [timeline, setTimeline] = useState(null);
  const [yourColor, setYourColor] = useState(null);
  const tl = gsap.timeline({ repeat: 0 }); // repeat indefinitely
  const [showYoCola, setShowYoCola] = useState(false);
  const [showYoMesaj, setShowYoMesaj] = useState(false);
  const [countDownPermission, setCountDownPermission] = useState(false);
  const [finnished, setFinnished] = useState(false);

  let paused = null;

  useEffect(() => {
    // Preload all sounds
    preLoadSounds();
  }, []);
  
  const handleCountdownFinish = useCallback(() => {
    document.querySelector('.countDownParent').style.display = 'none';
  }, []);

  useEffect(() => {
    // zoom out the window
    document.body.style.zoom = "100%";
  } , []);

  useEffect(() => {
    const interval = setTimeout(() => {
      setCount(1);
    }, 7000);
    return () => clearInterval(interval);
  } , [e]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setIsMobile(windowWidth < 768);
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    const requestPermissions = async () => {
      const requestPermission = async (event) => {
        if (event && typeof event.requestPermission === 'function') {
          const response = await event.requestPermission();
          if (response === 'granted') {
            setIsPermissionGranted(true);
          }
        }
      };
      await requestPermission(DeviceMotionEvent);
      await requestPermission(DeviceOrientationEvent);
    };

    if (!isPermissionGranted) {
      requestPermissions();
    }
  }, [isPermissionGranted]);

  useEffect(() => {
    const handleOrientation = (event) => {
      setGyroscopeData({
        gamma: event.gamma,
        alpha: event.alpha,
        beta: event.beta
      });
    };

    if (isPermissionGranted) {
      window.addEventListener('deviceorientation', handleOrientation);
      return () => window.removeEventListener('deviceorientation', handleOrientation);
    }
  }, [isPermissionGranted]);

  useEffect(() => {
    // add event listener to the window to colorChange
    window.addEventListener('colorChange', (e) => {
      if(e.detail.colorName) {
        setYourColorFunc(e.detail);
      }
      
    });

    return () => {
      window.removeEventListener('colorChange', (e) => {
        console.log(e.detail);
        if(e.detail.colorName) {
          setYourColorFunc(e.detail);
        }
      });
    }

  }, [] )

  const setYourColorFunc = (color) => {
    setTimeout(() => {

        sound = new Howl({
          src: [sounds['yo-voice-is']],
          preload: true,
          volume: 0.6
        });
        sound.play();

        setTimeout(() => {
          sound = new Howl({
            src: [sounds[color.colorName]],
            preload: true,
            volume: 0.6
          });

          sound.play();

          setTimeout(() => {
            setYourColor(color.colorName);

            // setTimeout(() => {
              console.log('yoCola');
              setShowYoCola(true); // Update the state to trigger rendering
            // }, 500)

          }, 2000);

        }, 3000);


        setTimeout(() => {
          document.querySelector('.finall').classList.add('elementHide');
        }, 8000);

        setTimeout(() => {
          console.log('yoMesaj');
          // turn visibility of finall to none
          setShowYoMesaj(true);
        }, 9500)

    }, 6000);
  }

  useEffect(() => {
  
    const gsapAnimation = () => {
      // Loop through each .textParent element if textRef is available
      if (isTextReady && textRef.current) {
        textRef.current.querySelectorAll('.temuri').forEach((element, index) => {
          // Add animations to the timeline ease in out
          tl.to(element, { duration: (index === 2) ? 3 : (index === 3) ? 4 : (index === 1) ? 2 : (index === 0) ? 2 : 1, 
            display: 'flex', 
            opacity: 1 , 
            ease: 'power2.inOut',
            onStart: () => {
              if(index === 0) {
                // document.querySelector('.slideUp').style.display = 'flex';
              }
            },
            onComplete: () => {
              if(index === 2) {
                // setTimeout(() => {
                  // document.querySelector('.ball').style.display = 'flex';
                  document.querySelector('.ball1').style.display = 'flex';
                  paused = tl.pause();
                  setPermission(!permission);
                // }, 3000);
              }
            }
          })
          .to(element, { duration: 1, display: 'none', opacity: 0 , ease: 'power2.inOut' }); // Fade out

        });
      }
    };
  
    // Trigger GSAP animations when the text is ready
    if (isTextReady) {
      gsapAnimation();
    }
  
    // Clean up function to stop the animation when the component unmounts
    return () => gsap.timeline().clear(); // Clear all GSAP animations
  }, [isTextReady]);
  
  useEffect(() => {
    // Update the state variable when textRef is set
    if (textRef.current) {
      setIsTextReady(true);
    }
  }, [textRef.current]);

  useEffect(() => {
    // add event listener to the window 
    window.addEventListener('click', async (e) => {
      // await Tone.start();
    });

    return () => {
      window.removeEventListener('click', async (e) => {
        // await Tone.start();
      });
    }
  }, []);


  const handleClick = () => {

    Tone.start();
    console.log('clicked');

    const secondAnimation = () => {
      console.log(clck);
        tl.play();
        // toggle the .ball to .ball.turnOff
        // document.querySelector('.ball').classList.add('turnOff');
        document.querySelector('.ball1').classList.add('turnOff');
        document.querySelector('.text17').remove();

        textRef.current.querySelectorAll('.temuri').forEach((element, index) => {

          document.querySelector('.text3').style.display = 'none';

          setTimeout(() => {
            if( index >= 2 ) {
              tl.to(element, { duration: (index === 1) ? 3 : (index === 0) ? 4 : (index === 2) ? 12 : (index === 4) ? 2 : 1, display: 'flex', opacity: 1 , ease: 'power2.inOut', onStart: () => {

                    if(index === 4) {
                      setTimeout(() => {
                        fixAudioContext();
                      }, 500);
                    }
                    if(index === 2) {

                      sound = new Howl({
                        src: [audio],
                        preload: true,
                        volume: 0.6
                      });

                      sound.play();

                      setColor('rgb(255, 255, 255)');
                      setTimeout(() => {
                          setCountDownPermission(true);
                      }, 2000);
                    }
                  },
                  onComplete: () => {

                      if(index === 2) {
                        console.log('finnished');
                        setFinnished(true);
                      }

                  }
                })
                .to(element, { duration: 1, display: 'none', opacity: 0 , ease: 'power2.inOut' }); // Fade out
            }
          })
        }, 2000);

        setTimeout(() => {
          setClicked(!clicked);
        }, 500);

        clck = false;

    }

    if( permission && clck ) {
      secondAnimation();
    }
    
  };

  const callback = (permission) => {

    if ( permission ) {
      setCountDownPermission(permission);
      console.log(permission);
    }

  }

  const handleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const text = `This brand new multimedia project
  researches painting art in the modern
  context. This project, inspired by new
  ideas, unites technologies, science, and
  human perspective. With the use of AI,
  the energy of light, and adaptive objects,
  the project aims to connect with the
  cosmos.
  The project produces colors and forms,
  translates human voice into color, and
  unites them. All this creates a charming
  scene that embodies the connection
  between time and the world. So, the
  project connects art with science and
  awakens a feeling of unity with the
  innite of the world.
  The anatomy of ''Timing Of Paint'' contains
  
  5 steps of consistent translating of paint-
  ings, so the painting be wider and more
  
  communicative in the modern world.`;

  return (
    <Pane>
      {isMobile ? (
        <Pane>
         
          <Pane className='full'>

            <Pane className='intro' position="absolute" overflow="hidden">
              <Pane position='relative' display='flex'>
                <Pane className='introFirst'>
                  <span>T</span><span>I</span><span>M</span><span>I</span><span>N</span><span>G</span> <span>O</span><span>F</span> <span>P</span><span>A</span><span>I</span><span>N</span><span>T</span>
                </Pane>
                <Pane className='introSecond'>
                  <Pane className='by'><span>b</span><span>y</span></Pane> 
                  <Pane className='otherBy'><span>e</span><span>n</span><span>s</span><span>ō</span></Pane>
                </Pane>
              </Pane>
            </Pane>

            <Pane position="relative" overflow="hidden" className='main'>
              <Pane position="absolute" width='100lvw' height='100lvh'>
                <img alt='' className='backGround' />
              </Pane>

              <Pane position="absolute" display="flex" zIndex="10" flexDirection='row' top={majorScale(15)} className='bgClass' height='150px'>
                <Pane position="relative" overflowX="hidden">
                  <Pane display='flex' lineHeight='7px' justifyContent='space-between'  flexDirection="column" className='leftText' paddingX='20px' position="absolute" color='white'>
                    <Pane>the</Pane>
                    <Pane>concept</Pane>
                    <Pane>of new</Pane>
                    <Pane>communication</Pane>
                  </Pane>

                  <Pane className='centerText' display="flex" flexDirection='row' color="white" zIndex="10" justifyContent="center" width="100vw">
                  <Pane className="text-container" >  
                      <Pane width='200px' ref={textRef} wordBreak='break-word' lineHeight='normal' letterSpacing='1px' className='textParent'>

                          <Pane flexDirection='column' gap='10px' className='text1 temuri'>
                             <Pane fontWeight='bold' letterSpacing='1px'justifyContent='center' display='flex'> HELLŌ</Pane>
                          </Pane>

                          <Pane flexDirection='column' gap='10px' className='text17 temuri'>
                              <Pane  letterSpacing='0' fontWeight='bolder' fontFamily='MyriadPro-Bold' fontSize='10px'>WELCOME TO <br/> TIMING OF PAINT</Pane>
                          </Pane>

                          <Pane flexDirection='column' gap='10px' letterSpacing='0' fontSize='10px' className='text3 temuri'>
                            <Pane justifyContent='center'  fontWeight='bolder' fontFamily='MyriadPro-Bold' display='flex'>LET'S SEND <br/> A MESSAGE <br/> TO COSMOS !</Pane> 
                            <Pane fontFamily='Montserrat-Medium' className='slideUp' display='flex' opacity='0.8'> </Pane>
                          </Pane>

                          <Pane className="text5 temuri">
                            {/* <Pane className='sound-wave-2'>
                              <Pane className="line"></Pane>
                              <Pane className="line"></Pane>
                              <Pane className="line"></Pane>
                              <Pane className="line"></Pane>
                            </Pane> */}
                            
                          </Pane>

                            {yourColor && 
                              (

                                <Pane fontSize='10px' lineHeight='normal' letterSpacing='' flexDirection='column' gap='10px' display='flex' className='text10'>
                                  <Pane className='finall'>
                                    <Pane justifyContent='center' className='yoVoice' fontWeight='bolder' fontFamily='MyriadPro-Bold'>YOUR</Pane>
                                    <Pane justifyContent='center' className='yoVoice' fontWeight='bolder' fontFamily='MyriadPro-Bold'>VOICE IS</Pane>
                                    {showYoCola && ( // Conditionally render the components
                                      <Pane className='yoCola' fontWeight='bold' letterSpacing='1px' justifyContent='center' fontSize='15px' fontFamily=''>{yourColor}</Pane>
                                    )}
                                  </Pane>
                                  <Pane>
                                    {showYoMesaj && (
                                      <Pane fontFamily='Montserrat-Medium' opacity='0.8' className='slideUp yoMesaj' >your message <br/> is sent!</Pane>
                                    )}
                                  </Pane>
                                </Pane>  
                                
                              )
                            }

                          <Pane className='text11 temuri'></Pane>  

                      </Pane>
                  </Pane>
                  </Pane>
                </Pane>
              </Pane>

              <CanvasComponent clicked={clicked} appColor={color} callBack={callback} finnished={finnished} />

              <Pane position="absolute"  className='installation' width='100svw' height='100svh'>
                <Pane position='relative' display='flex' flexDirection='column' justifyContent='center' alignItems='end'>
                  <Pane position='relative' color='#fff' fontSize='12px' display='flex' justifyContent='center' alignItems='center' className='installationImage'>
                    {/* <Pane className='ball' fontFamily='MyriadPro-Bold' lineHeight='9px' position='absolute'><Pane width='100vw' fontSize='4px' wordBreak='none' >{ballText}</Pane></Pane> */}
                  </Pane>
                </Pane>
              </Pane>

              <Pane position="absolute"  className='installation1' width='100svw' height='100svh'>
                <Pane position='relative' display='flex' flexDirection='column' justifyContent='center' alignItems='end'>
                  <Pane position='relative' color='#fff' fontSize='12px' display='flex' justifyContent='center' alignItems='center' className='installationImage1'>
                    <Pane className='ball1'  fontFamily='MyriadPro-Bold' lineHeight='9px' position='absolute'>
                      <Pane position='relative' width='100%' height='100%' >
                        <Pane className='clickSpeak click' opacity='0.8' wordBreak='none' >SPEAK</Pane><Pane className='clickSpeak speak' wordBreak='none' >CLICK</Pane>
                      </Pane>
                    </Pane>
                    <Pane className='countDownParent' position='relative' fontSize='2px' height='4.5svh' width='4.5svh'>
                      <Pane onClick={clck ? handleClick : null} fontFamily='MyriadPro-Bold' className="text5 countDown">
                        {countDownPermission && (
                          <Countdown 
                              initialTime={11}  // start with 9-second countdown
                              onFinish={handleCountdownFinish} 
                          /> 
                        )}
                      </Pane> 
                    </Pane>
                  </Pane>
                </Pane>
              </Pane>

              {isDialogOpen && (
                <>
                  <Pane zIndex='120' onClick={() => setIsDialogOpen(false)} className={`dialog ${isDialogOpen ? 'dialog-open' : ''}`}><Pane color='white' textAlign='left' marginTop='40%' padding='20px'>{text}</Pane></Pane>
                  <DialogOverlay onClick={() => setIsDialogOpen(false)} />
                </>
              )}

              <Pane onClick={handleDialog} zIndex='120' bottom='0' right='0' display='flex' justifyContent='center' marginBottom='20px'  marginRight="20px" position='absolute' width='30px' height='30px' alignItems='center' ><img src={Info} alt='' className='infoButton'/></Pane>
              <Pane zIndex='120' className='vertical'><Pane backgroundClip='text' textAlign='center' fontSize='7px'  lineHeight='9px' width='300px' position='absolute' className='infoTextLeft' zIndex='150'>TIMING OF PAINT by enso (c)2024</Pane></Pane>

            </Pane>
          </Pane>
        </Pane>
      ) : (
        <Pane className='main'>Desktop</Pane>
      )}
    </Pane>
  );
};

export default App;
