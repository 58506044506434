import React, { useEffect, useState } from 'react';
import { Pane } from 'evergreen-ui';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const LoadingElement = () => (
    <div className="loaderMain">
        <div className="loader">
            <div className="inner"></div>
        </div>
    </div>
)

function preloadImages(imageUrls) {
    return new Promise((resolve, reject) => {
        let loaded = 0;
        const total = imageUrls.length;
        const images = [];

        const onLoad = () => {
            loaded++;
            if (loaded === total) {
                resolve(images);
            }
        };

        imageUrls.forEach(url => {
            const img = new Image();
            img.src = url;
            img.onload = onLoad;
            img.onerror = onLoad;
            images.push(img);
        });
    });
}

function preloadFonts(fontUrls) {
    return Promise.all(fontUrls.map(url => new Promise((resolve, reject) => {
        const style = document.createElement('style');
        style.textContent = `@font-face { font-family: "FontName"; src: url(${url}) format('woff2'); }`;
        document.head.appendChild(style);
        style.onload = resolve;
        style.onerror = reject;
    })));
}

function preloadAssets() {
    return Promise.all([
        preloadImages(['./assets/images/5.svg', './assets/images/bgr.svg', './assets/images/infoButton.svg', './assets/images/installation-full_smaller', './assets/images/installation-full-01.svg', './assets/images/installation-full.svg', './assets/images/mainInstallation.svg']),
        preloadFonts(['./assets/images/aliens_and_cows_trial.ttf', './assets/images/Montserrat-Black.ttf', './assets/images/Montserrat-Medium.ttf', './assets/images/MyriadPro-Bold.otf'])
    ]);
}

function Preloader({ children }) {
    const [preloading, setPreloading] = useState(true);

    useEffect(() => {
        preloadAssets().then(() => setPreloading(false));
    }, []);

    return preloading ? <LoadingElement /> : children;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Preloader>
        <App />
    </Preloader>
);
