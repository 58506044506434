import React, { useRef, useEffect, useState } from 'react';
import { Pane } from 'evergreen-ui';
import * as Tone from 'tone';
// react-device-detect
import { isIOS, isAndroid } from 'react-device-detect';

let perm = true;
let arr = [];
let count = Math.PI;
let fftValue;
let something;
let someColor;
let svetPermission = true;
let mic;
let llll;
let delpinisParpli = true;


const CanvasComponent = ( {clicked, appColor = false, callback = () => {}, finnished} ) => {
    const canvasRef = useRef(null);
    const [isMicStarted, setIsMicStarted] = useState(false);
    const [analyser, setAnalyser] = useState(null);
    const [prm, setPrm] = useState(false);
    const [ color, setColor ] = useState(null);
    let average

    // Inside your component's cleanup or 'beforeunload' handler
async function closeMicrophoneAndCleanup() {
  if (mic) {
      try {
          mic.disconnect();
          mic.dispose();
          mic.close(); 
      } catch (error) {
          console.error("Error while closing the microphone:", error); 
      }
  }

  if (analyser) {
      analyser.dispose();
  }

  if ( llll ) {
    llll.dispose();
  }

  if (Tone.context.state === 'running') {
      Tone.context.close();
  }

  // Delay for indicator update
  await new Promise(resolve => setTimeout(resolve, 500)); 

}

useEffect(() => {

  arr.push(llll);

  if ( finnished ) {
    setTimeout(() => {
      arr.forEach((analyser) => {
        if (llll) {
          closeMicrophoneAndCleanup();
        }
      })
    },1000);
  }
  
}, [analyser, finnished]) // Adjust dependencies as needed

window.addEventListener('beforeunload', closeMicrophoneAndCleanup);

    const startMicrophone = async () => {
      try {
        // enable microphone
        llll = new Tone.Analyser('fft', 2048);
        mic = new Tone.UserMedia();
        mic.connect(llll);
        await mic.open();
        console.log('Microphone is started');
        setIsMicStarted(true);
        setAnalyser(llll);
      } catch (error) {
          console.error("Error accessing microphone:", error);
      }
    };

    useEffect(() => {
        setColor(appColor);
    }, [appColor])

    useEffect(() => {
        if ( finnished ) {
          setTimeout(() => {
            setPrm(true);
          },1000);
        }
    }, [finnished]);

    useEffect(() => {
        // if microphone permission is not granted, ask for permission
        if( clicked ) {
          if ( window.navigator.permissions.query({name: 'microphone'}).then((result) => {
            if (result.state === 'granted') {
              window.navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                callback && callback(true);
              }).catch((err) => {
                console.error(err);
                callback && callback(false);
              });
            } else {
              window.navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                callback && callback(true);
              }).catch((err) => {
                console.error(err);
                callback && callback(false);
              });
            }
          }) );
        }
    }, [ clicked ]);
    
    useEffect(() => {
        // add event listener to the window to stop the microphone when the window is closed
        window.addEventListener('beforeunload', () => {
            if (analyser) analyser.dispose();
        });

        return () => {
            window.removeEventListener('beforeunload', () => {
                if (analyser) analyser.dispose();
            });
        }
    }, [analyser]);

    useEffect(() => {
        if (clicked && delpinisParpli) {
            setTimeout(() => {
              startMicrophone();
            }, 2000);
            delpinisParpli = false;
        }
    }, [clicked, isMicStarted]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const draw = () => {
            
          if (analyser || appColor) {
              if(analyser) {
                fftValue = analyser?.getValue();
                average = calculateAverage(fftValue);
                setColor(getColorFromFrequency(average))
              }

              if ( prm === false ) {
                if ( svetPermission ) {

                // star wars effect
                  count = count * 1.21;
                  if(count > 2000) count = 2000; 
                  if( isMicStarted && perm) {
                    count = Math.PI;
                    perm = false;
                  }

                  ctx.clearRect(0, 0, canvas.width,  2000);

                  if ( color ) {
                    ctx.strokeStyle = appColor;
                  }else {
                    setColor(false);
                    ctx.strokeStyle = 'rgba(255, 255, 255, 1)'
                    if ( getColorFromFrequency(average) !== '#fffcfc' ) {
                      ctx.strokeStyle = getColorFromFrequency(average);
                      something = average
                      someColor = getColorFromFrequency(average);
                    }
                  }

                  // ctx.strokeStyle = color ? color : appColor;
                  ctx.beginPath();
                  ctx.lineWidth = 13;       // Adjust for halo thickness
                  ctx.filter = 'blur(3px)'; // Adjust for halo blur
                  ctx.stroke();
                  
                  ctx.filter = 'none';
                  ctx.globalAlpha = 1;   
                  ctx.moveTo(canvas.width / 2, canvas.height / 2 );
                  ctx.lineTo(window.innerWidth / 2, window.innerHeight / 2 - count);
                  ctx.stroke();
                  ctx.filter = `contrast(2) saturate(0.5) hue-rotate(${average ? average : 0}deg)`;
                  ctx.filter = 'white';
                  requestAnimationFrame(draw);
                }else {
                    // disable microphone
                    (async () => {
                      // disable microphone
                      mic.disconnect();
                      mic.dispose();
                      mic.close();
    
                      let audioCtx = new (window.AudioContext || window.webkitAudioContext)();
                      if ( audioCtx.state !== 'closed' ) {
                        audioCtx.close();
                      }
                      
                      if ( Tone.context.state === 'running' ) {
                        Tone.context.close();
                      }


                      closeMicrophoneAndCleanup();
                      llll.dispose();
    
                    })();
                }
              } else {
                svetPermission = false
                // dicrease to 0
                // count =- 1;
                // if ( count < 0 ) count = 0

                ctx.clearRect(0, 0, canvas.width,  2000);
                ctx.strokeStyle = someColor
                ctx.beginPath();
                ctx.lineWidth = 13;       // Adjust for halo thickness
                ctx.filter = 'blur(3px)'; // Adjust for halo blur
                ctx.stroke();
                
                ctx.filter = 'none';
                ctx.globalAlpha = 1;   
                ctx.moveTo(canvas.width / 2, canvas.height / 2 );
                ctx.lineTo(window.innerWidth / 2, window.innerHeight / 2 - count);
                ctx.stroke();
                ctx.filter = `contrast(2) saturate(0.5) hue-rotate(${average ? average : 0}deg)`;
                ctx.filter = 'white';

                let avgNumber = Math.round(Math.floor(something) / 10) * 10;
                let exactColor = isAndroid ? getColorMap2().find((colorObj) => colorObj.frequency === avgNumber) : getColorMap().find((colorObj) => colorObj.frequency === avgNumber);

                // disable microphone
                (async () => {
                  let stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false }) 
                  stream.getTracks().forEach((track) => {
                    track.stop();
                  })
                })();
                exactColor.color = exactColor.color.toUpperCase();
                let customEvent = new CustomEvent('colorChange', { detail: { colorName: exactColor.color, color: `rgba(${exactColor.r}, ${exactColor.g}, ${exactColor.b}, 1)` } });
                window.dispatchEvent(customEvent);

                setPrm(true);

                setTimeout(() => {

                  canvas.classList.add('canvasHide');
                  
                }, 9000);
                  
              }

          }

        };

        if (isMicStarted || appColor) {
            draw();
        }

        return () => {
            // if (analyser) analyser.dispose();
        };
    }, [isMicStarted, analyser, appColor, clicked, prm]);

    const calculateAverage = (fftValue) => {
      let sum = fftValue.reduce((acc, val) => acc + val, 0);
      return Math.floor(830 - Math.abs(sum / fftValue.length / 255 * 1000));
    };

    const getColorFromFrequency = (frequency) => {
      let color = '#fffcfc';
      // if navigator platform is android, return white color
      const colorMap = isAndroid ? getColorMap2() : getColorMap();
      for (let i = 0; i < colorMap.length - 1; i++) {
        const colorObj = colorMap[i];
        if (frequency >= colorObj.frequency && frequency <= colorMap[i + 1].frequency) {
          color = `rgba(${colorObj.r}, ${colorObj.g}, ${colorObj.b}, 1)`;
          break;
        }
      }
      return color;
    };

    const getColorMap = () => [
      { frequency: 300, r: 186, g: 32, b: 51, color: 'red'},
      { frequency: 310, r: 186, g: 32, b: 51, color: 'red' },
      { frequency: 320, r: 186, g: 32, b: 51 , color: 'red'},
      { frequency: 330, r: 186, g: 32, b: 51 , color: 'red'},
      { frequency: 340, r: 232, g: 244, b: 62, color: 'yellow' },
      { frequency: 350, r: 232, g: 244, b: 62, color: 'yellow'},
      { frequency: 360, r: 232, g: 244, b: 62, color: 'yellow' },
      { frequency: 370, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 380, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 390, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 400, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 410, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 420, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 430, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 440, r: 121, g: 196, b: 225, color: 'cyan'},
      { frequency: 450, r: 121, g: 196, b: 225, color: 'cyan'},
      { frequency: 460, r: 121, g: 196, b: 225, color: 'cyan'},
      { frequency: 470, r: 121, g: 196, b: 225, color: 'cyan'},
      { frequency: 480, r: 47, g: 137, b: 247, color: 'blue'},
      { frequency: 490, r: 47, g: 137, b: 247, color: 'blue'},
      { frequency: 500, r: 47, g: 137, b: 247, color: 'blue'},
      { frequency: 510, r: 47, g: 137, b: 247, color: 'blue'},
      { frequency: 520, r: 209, g: 85, b: 175, color: 'pink'},
      { frequency: 530, r: 209, g: 85, b: 175, color: 'pink'},
      { frequency: 540, r: 209, g: 85, b: 175, color: 'pink'},
      { frequency: 550, r: 209, g: 85, b: 175, color: 'pink'},
      { frequency: 560, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 570, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 580, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 590, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 600, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 610, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 620, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 630, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 640, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 650, r: 152, g: 59, b: 255, color: 'violet'},
    ];

    const getColorMap2 = () => [
      { frequency: 10, r: 186, g: 32, b: 51, color: 'red'},
      { frequency: 110, r: 186, g: 32, b: 51, color: 'red' },
      { frequency: 120, r: 186, g: 32, b: 51 , color: 'red'},
      { frequency: 130, r: 186, g: 32, b: 51 , color: 'red'},
      { frequency: 140, r: 232, g: 244, b: 62, color: 'yellow' },
      { frequency: 150, r: 232, g: 244, b: 62, color: 'yellow'},
      { frequency: 160, r: 232, g: 244, b: 62, color: 'yellow' },
      { frequency: 170, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 180, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 190, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 200, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 210, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 220, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 230, r: 75, g: 151, b: 30, color: 'green'},
      { frequency: 240, r: 121, g: 196, b: 225, color: 'cyan'},
      { frequency: 250, r: 121, g: 196, b: 225, color: 'cyan'},
      { frequency: 260, r: 121, g: 196, b: 225, color: 'cyan'},
      { frequency: 270, r: 121, g: 196, b: 225, color: 'cyan'},
      { frequency: 280, r: 47, g: 137, b: 247, color: 'blue'},
      { frequency: 290, r: 47, g: 137, b: 247, color: 'blue'},
      { frequency: 300, r: 47, g: 137, b: 247, color: 'blue'},
      { frequency: 310, r: 47, g: 137, b: 247, color: 'blue'},
      { frequency: 320, r: 209, g: 85, b: 175, color: 'pink'},
      { frequency: 330, r: 209, g: 85, b: 175, color: 'pink'},
      { frequency: 340, r: 209, g: 85, b: 175, color: 'pink'},
      { frequency: 350, r: 209, g: 85, b: 175, color: 'pink'},
      { frequency: 360, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 370, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 380, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 390, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 400, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 410, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 420, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 430, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 440, r: 152, g: 59, b: 255, color: 'violet'},
      { frequency: 450, r: 152, g: 59, b: 255, color: 'violet'}
      
    ]

    return (
      <>
        <Pane >
          <canvas className='canvasObj' width={window.innerWidth} height={window.innerHeight} ref={canvasRef}></canvas>
        </Pane>
      </>
    );
    
};

export default CanvasComponent;
