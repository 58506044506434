import React, { useEffect, useState } from 'react';

const Countdown = ({ initialTime = 60, onFinish }) => {
  const [secondsRemaining, setSecondsRemaining] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsRemaining((prevSeconds) => {
        if (prevSeconds <= 0) {
          clearInterval(intervalId);
          if (onFinish) onFinish();  // Call the callback when finished
          return 0;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [initialTime, onFinish]);

  return (
    <div className="countdown">{secondsRemaining}</div>
  );
};

export default React.memo(Countdown);
